<template>
  <div class="userLogin">
    <div class="loginBox">
      <div class="loginFrom">
        <!-- logo图片 -->
        <div class="fromBox">
          <!-- 标题 -->
          <h1>Reset Password</h1>
          <p>Reset buyer account password</p>
          <!-- 修改密码表单 -->
          <el-form :model="loginForm"
                   :rules="loginRules"
                   ref="loginForm"
                   class="demo-ruleForm">
            <!-- 密码 -->
            <el-form-item label="password"
                          prop="pass">
              <el-input type="password"
                        show-password
                        v-model="loginForm.pass"
                        autocomplete="off"></el-input>
            </el-form-item>
            <!-- 确认密码 -->
            <el-form-item label="Confirm password"
                          prop="checkPass">
              <el-input type="password"
                        show-password
                        v-model="loginForm.checkPass"
                        autocomplete="off"></el-input>
            </el-form-item>
            <!-- 修改 -->
            <el-form-item>
              <el-button type="primary"
                         @click="forgetPass()" :loading="$store.state.loadingBool">Confirm</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入接口
import {
  modifyPassword // 修改密码第二步
} from '../../api/buyerFront'
export default {
  data () {
    // 密码
    var validatePass = (rule, value, callback) => {
      // 密码正则
      const passReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/
      // 密码框value判断
      if (value === '') {
        callback(new Error('Please input a password'))
      } else if (!passReg.test(value)) {
        callback(new Error('The password should contain at least numbers and English, and the length should be 6-20'))
      } else {
        if (this.loginForm.checkPass !== '') {
          this.$refs.loginForm.validateField('registerCheckpass')
        }
        callback()
      }
    }
    // 确认密码
    var validatePass2 = (rule, value, callback) => {
      // 确认密码框value判断
      if (value === '') {
        callback(new Error('Please enter the password again'))
      } else if (value !== this.loginForm.pass) {
        callback(new Error('The two passwords are inconsistent!'))
      } else {
        callback()
      }
    }
    return {
      // 修改密码表单
      loginForm: {
        pass: '', // 密码
        checkPass: '' // 确认密码
      },
      signNum: '',
      // 表单验证规则
      loginRules: {
        // 密码
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        // 确认密码
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      loadingBool: false
    }
  },
  created () {
    // 获取sign值
    this.signNum = this.$route.query.sign
    this.$store.commit('getLoading', false)
    // // (this.$route.query.sign)
    const that = this
    // 当前页面监视键盘输入
    document.onkeydown = function (e) {
      const e1 =
        e || event || window.event
      if (e1.keyCode === 13) {
        that.forgetPass()
      }
    }
  },
  methods: {
    // 修改密码
    forgetPass () {
      const that = this
      // 判断密码框是否为空
      if (this.loginForm.loginEmail !== '') {
        this.$store.commit('getLoading', true)
        that.$store.state.loadingBool = true
        // 调用修改密码第二步接口
        modifyPassword({
          mail: this.signNum, // sign值
          password: this.loginForm.pass // 密码
        }).then(result => {
          that.$store.state.loadingBool = false
          // 接口调用成功 状态码为200
          if (result.data.code === 200) {
            this.$store.commit('getLoading', false)
            this.$message.success(result.data.message) // 成功弹框
            this.$router.push({ path: '/F_login' }) // 跳转登录页面
          } else {
            this.$store.commit('getLoading', false)
            this.$message.error(result.data.message) // 失败弹框
          }
          // // ('result ==>', result)
        }).catch(err => {
          return err
        })
      } else {
        this.$message.error('Please enter a new password')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.userLogin {
  min-height: 600px;
  .loginBox {
    min-height: 550px;
    position: relative;
    .loginFrom {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 500px;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      img {
        display: block;
        width: auto;
        height: 40px;
      }
      .fromBox {
        background-color: white;
        padding: 25px;
        width: 100%;
        border: 1px solid #eeeeee;
        h1 {
          text-align: center;
          background-color: #f8ac59;
          font-size: 24px;
          font-weight: normal;
          line-height: 30px;
          color: white;
          padding: 15px 0;
        }
        p {
          font-size: 16px;
          line-height: 24px;
          color: #6c757d;
          text-align: left;
          padding-left: 25px;
          margin-top: 25px;
        }
        .el-form {
          padding: 0 25px;
          .el-form-item {
            margin: 20px 0 0 0;
            &:nth-child(3) {
              margin: 0;
            }
          }
          .el-button {
            margin-top: 20px;
            width: 100%;
            background-color: #f8ac59;
            border-color: #f8ac59;
          }
        }
      }
    }
  }
  // background: red;
}
</style>
